import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'
import HubspotForm from "react-hubspot-form"
import AOS from 'aos';
import "aos/dist/aos.css";

const RewardsCatalog = ({ location }) => {

  useEffect(() => {
    AOS.init({
        once:true
    });
  }, []);

  return (
    <Layout location={location}>
    <section class="w-full flex place-content-center relative bg-indigo-100 overflow-x-hidden py-9 xl:py-5 overflow-y-hidden z-0 px-3 lg:px-0">
        <div class="max-w-7xl grid xl:grid-cols-2 justify-center items-start z-10 lg:px-3 relative">    
            <div class="w-full xl:w-full flex flex-col text-center lg:text-left justify-center xl:items-start items-center xl:mr-5 xl:pt-12 xl:pb-20 z-10">
                <div class="mt-2 mb-6 xl:mb-2 flex justify-center items-center px-4 py-2 rounded-full border border-gray-darklight bg-indigo-50">
                   
                    <h2 class="text-purple-100 text-sm lato">GLOBAL REWARDS CATALOG</h2>
                </div>
                <h1 class="text-purple-100 newbannerSectionTitle text-4xl xl:text-7xl font-bold text-center xl:text-left lg:pr-3 text-center xl:w-10/12"><span class="relative"><img decoding="async" class="absolute -bottom-1 right-0 xl:right-0" src="https://res.cloudinary.com/vantagecircle/image/upload/v1648619895/gatsbycms/uploads/2022/03/hero-strokesvg.svg" /></span>Something <span class="text-orange"></span><br/> for everyone. <span class="text-orange"></span></h1>
                <p class="my-8 text-lg xl:text-xl text-center xl:text-left text-purple-100 md:w-10/12">Give your employees the gift of choice and a hassle-free rewards redemption experience with our Global Rewards Catalog.</p>
                <div class="xl:flex justify-center items-center lg:justify-start  w-5/6 md:w-1/3 xl:w-1/3 cursor-pointer my-8 z-10">
                    <a class="vc-new-orange-btn-rounded-full lato rounded-full mb-3 xl:mb-0 xl:mr-2" href="#form">Request a demo</a>
                </div>
            </div>
            <div class="w-full xl:w-5/12 h-96 md:h-96 md:my-9 my-0 xl:h-80"></div>
            <div class="absolute -bottom-10 md:-bottom-72 md:left-10 lg:right-1/4 xl:-bottom-0 xl:right-20 flex justify-center xl:justify-end items-center h-1/2 md:h-full z-0 mt-6 xl:mt-0 lg:ml-3 px-3 aos-init aos-animate" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600"><img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/Rewards-Catalog-Hero.webp" alt="Vantage Rewards" width="500" /></div>
        </div>
        <div class="absolute right-0 top-0"><img src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/03/hero-oval.webp" width="500" /></div>
    </section>
    <section class="flex w-full place-content-center py-5 lg:py-10 bg-white overflow-x-hidden px-3 lg:px-0">
            <div class="flex flex-col place-items-center max-w-7xl px-3">
                <h2 class="newbannerSectionTitle text-3xl md:text-4xl xl:text-7xl font-bold text-center text-gray-250 my-8 px-1"><span class="text-orange"></span>Wow your employees with an extensive Rewards Catalog like no other</h2>
                <div class="w-full  flex flex-col justify-center items-center mt-5 md:mt-10 py-3 md::py-6 xl:py-0">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-full mb-0 lg:my-8 relative">
                        <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-center z-10">
                            <h2 class="text-gray-250 rewardsSectionTitle my-3 w-full">Browse through <span className='text-orange'>Amazon Catalog</span></h2>
                            <p class="text-gray-250 text-lg xl:text-xl my-3 leading-8">Whether your employees are looking to stock up on household essentials or treat themselves to something special, they can do it all by exchanging their reward points for products on the Amazon Store directly through our platform. Employees can also enjoy Prime services without Prime membership.</p>
                        </div>
                        <div class="w-full flex justify-center items-center z-10">
                            <img decoding="async" className='transform lg:scale-90' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/rewardcatalog_amazon.png" width= "703" height="723" />
                        </div>
                        
                    </div>
                    <div class="flex flex-col lg:flex-row-reverse justify-center items-start w-full my-12 relative">
                        <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10">
                            <h2 class="text-gray-250 rewardsSectionTitle my-3 w-full">Thousands of <span className='text-orange'>gift cards </span>waiting to be redeemed</h2>
                            <p class="text-gray-250 text-lg xl:text-xl my-3 leading-8">Amplify the collective joy in your workplace by offering your employees the flexibility to exchange their reward points for gift cards ranging from a wide variety of brands, local to global. Our extensive selection includes gift cards from the world's most popular brands, spanning fashion, travel, food, and more.</p>
                        </div>
                        <div class="w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
                            <img decoding="async" loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/rewardcatalog_giftcard.png" />
                        </div>
                       
                    </div>
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-ful my-12 relative">
                        <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start z-10">
                            <h2 class="text-gray-250 rewardsSectionTitle my-3 w-full">Experience the <span className='text-orange'>experiences</span> </h2>
                            <p class="text-gray-250 text-lg xl:text-xl my-3 leading-8">Give your employees a whole new redemption option to break the daily mundane & live the perfect work-life balance with relaxing and luxurious hotel stays, trekking and camping in nature’s lap, budget-friendly holiday packages & so much more.</p>
                        </div>
                        <div class="w-full flex justify-center items-center z-10">
                            <img decoding="async" loading="lazy" className='transform lg:scale-90' src="https://res.cloudinary.com/vantagecircle/image/upload/v1657697160/gatsbycms/uploads/2023/05/rewardcatalog_experiences.png" width="600" height="701" />
                        </div>
                        
                    </div>
                    <div class="flex flex-col lg:flex-row-reverse justify-center items-start w-full my-10 relative">
                        <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start mx-0 lg:mx-8 mb-8 lg:mb-0 z-10">
                            <h2 class="text-gray-250 rewardsSectionTitle my-3 w-full"><span className= "text-orange">Merchandise</span> to uplift life</h2>
                            <p class="text-gray-250 text-lg xl:text-xl my-3 leading-8">Give your employees the flexibility to redeem reward points against merchandise of their choice, from home essentials to work necessities & more, across top global brands & get them delivered to their doorstep in no time.</p>
                        </div>
                        <div class="w-full flex justify-center items-center mx-0 lg:mx-8 mt-8 lg:mt-0 z-10">
                            <img decoding="async" loading="lazy" className='transform lg:scale-90' src="https://res.cloudinary.com/vantagecircle/image/upload/v1657697163/gatsbycms/uploads/2023/05/rewardcatalog_merchandise.png" width="576" />
                        </div>
                       
                    </div>
                </div>
                <div className='mx-auto w-11/12 md:w-4/12 lg:w-3/12 xl:w-2/12 my-5 py-5'>
                        <a className='vc-new-orange-btn-rounded-full rounded-xl' href= "#form">Request a Demo</a>
                </div>
            </div>     
        </section>
        <section class="w-full flex place-content-center relative overflow-x-hidden py-0 lg:py-16 2xl:py-20 overflow-y-hidden rewards-bg-1 px-3 lg:px-0" id="FormVR">
            <div class="lg:flex max-w-7xl px-3">
                <div class="card-1 w-full mb-10 md:mb-0 mt-5 xl:mt-0 lg:w-1/2 xl:w-2/3 2xl:w-2/3 flex flex-col xl:justify-center">
                    <h2 class="text-3xl xl:text-5xl text-center lg:text-left text-gray-250 font-bold pt-5 mb-8 md:px-12 lg:px-0">Please tell us more about your requirements</h2>
                    <div className='flex justify-center lg:justify-end lg:w-11/12 2xl:w-full xl:pr-5 2xl:pr-10 transform rotate-90 lg:rotate-0'><img loading='lazy' src='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/11/Group-2-2.webp' height="110" width="110" /></div>
                    
                </div>
                <div class="card-1 w-full mb-10 md:mb-0 mt-10 xl:mt-0 lg:w-1/2 xl:w-5/12 2xl:w-1/3 flex flex-col place-items-center rounded-lg px-6 py-10 rewards-bg-2" id="form">
                    <h2 class="text-2xl lato text-gray-250 pt-5 mb-8 md:px-12 lg:px-0 text-center">You are now one step closer to fostering a dynamic culture of appreciation</h2>
                    <div class="w-full flex justify-center items-center lato team">
                        <div class = "flex justify-center items-center">
                            <HubspotForm
                            portalId='6153052'
                            formId='d1883134-7ac4-4ec9-b176-10788250fd39'
                            onSubmit={() => console.log('Submit!')}
                            onReady={(form) => console.log('Form ready!')}
                            loading={<div>Loading...</div>}
                            />
                        </div>
                    </div>
                    <a href="/privacy-policy/" class="text-gray-250 underline lato text-sm">Read our Privacy Policy</a>               
                </div>
            </div>
        </section>
        <section className='pt-10 pb-10'>
            <div className='max-w-5xl mx-auto px-5'>
                <h2 className='text-gray-250 text-3xl xl:text-6xl max-w-3xl mx-auto mt-10 mb-5 font-bold text-center'>More <span className='text-orange'>benefits</span></h2>
                <div className='grid xl:grid-cols-2 gap-5 xl:gap-16 xl:mt-20 xl:mb-10'>
                    <div className='my-5 flex-col justify-center items-center xl:my-0 border rounded-lg px-6 py-3'>
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/save-on-services.webp" alt="save-on-services" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Save upto 15% on Service Awards</h2>
                    </div>
                    <div className='my-5 xl:my-0 border rounded-lg px-6 py-3'>
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/drive-behaviour.webp" alt="drive-behaviour" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Drive desired behavior with Rewards</h2>
                    </div>
                    <div className='my-5 xl:my-0 border rounded-lg px-6 py-3 xl:pb-8'>
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/save-on-transit.webp" alt="save-on-transit" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Save on transit with Amazon Prime</h2>
                    </div>
                    <div className='my-5 xl:my-0 border rounded-lg px-6 py-3 xl:pb-8'>
                        <div className='w-full'><img className= 'py-4 mx-auto' src= "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/04/earn-points.webp" alt="earn-points" width="80"/></div>
                        <h2 className='py-2 text-2xl text-center'>Spend points to shop</h2>
                    </div>

                </div>
            </div>
        </section>  
    </Layout>
  )
}

export default RewardsCatalog